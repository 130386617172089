export type TransferStatus = {
    name: string
    rank: number
}

export enum MedicalUnitType {
    Cardiology = 1,
    PACU = 2,
    Psychiatry = 3,
    FamilyMed = 4,
    Palliative = 5,
    HAD = 6,
    Oncology = 7,
    SpecialUnits = 8,
    IMNonTeach = 9,
    Geriatrics = 10,
    Surgery = 11,
    MSICU = 12,
    SSU = 13,
    Neurology = 14,
    IMTeach = 15,
}

export type CompletedImage = {
    patientId: string
    uniqueId: number
    imageDescription: string
    imageStatus: string
    imageCreationDatetime: string
}

export type Patient = {
    id: number
    patientId: string
    visitDiagnosis: string
    visitLengthOfStaySinceAdmissionHours: number
    visitExpectedPlannedDischargedHours: number
    visitFollowingDoctorName: string
    locationServiceName: string
    locationUnitName: string
    locationRoomName: string
    locationBedName: string
    actualTransferStatus: string
    transferStatuses: TransferStatus[]
    patientRecord: string
    patientFirstName: string
    patientName: string
    patientGender: string
    patientAge: number
    patientProvenance: string | null
    patientTerritory: string | null
    patientTerritoryAcronym: string | null
    patientCareFactors: (string | null)[]
    vitalsEwsNicuResults: string | null
    pendingLabsCount: number | null
    completedLabsCount: number | null
    completedImages: CompletedImage[] | null
    completedImagesCount: number | null
    pendingImages: CompletedImage[] | null
    pendingImagesCount: number | null
    lastModifiedDate: string
}

export type WardViewData = {
    wards: Patient[]
}

export type WardViewQueryParams = {
    medicalUnitType: MedicalUnitType
    page?: number
    size?: number
}
