import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
// import { criticalCareBaseUrl } from '../../../utils/API'
import {
    cvicuOccupancyMock,
    cvicuOutflowMock,
    cvicuSummaryMock,
    edPodsMock,
    msicuOccupancyMock,
    msicuOutflowMock,
    msicuSummaryMock,
    orRoomsMock,
    pacuMock,
    resusMock,
} from '../../../utils/__Mocks__/CriticalCareMock'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import {
    AdmissionsData,
    AdmittingType,
    CriticalCareTotalCurrentInflowsResponse,
    CriticalCareTotalCurrentOutflowsResponse,
    CurrentDemographicsResponse,
    EmergencyCategory,
    InflowItem,
    Occupancy,
    OperatingRoomCurrentInflowsResponse,
    OutflowItem,
    PacuResponce,
    ResusPodsResponce,
} from './types'
import { createQueryFn } from '../helpers/createQueryFn'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

const criticalCarebaseURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}/api/criticalcare`

const MocktResusPodsData = {
    RESUS: resusMock,
    PODS: edPodsMock,
}

const MockTotalCurrentInflow = {
    CVICU: cvicuSummaryMock,
    MSICU: msicuSummaryMock,
}

const MockCurrentDemographics = {
    CVICU: cvicuOccupancyMock,
    MSICU: msicuOccupancyMock,
}

const MockOutflows = {
    CVICU: cvicuOutflowMock,
    MSICU: msicuOutflowMock,
}

export const criticalCareApi = createApi({
    reducerPath: 'CriticalCareApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: criticalCarebaseURL,
        prepareHeaders: (headers, { getState }) => {
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }
            return headers
        },
    }),

    tagTypes: [
        'msicuOccupancy',
        'cvicuOccupancy',
        'resusInflow',
        'edPodsInflow',
        'operatingRoom',
        'pacuInflow',
        'msicuOutflow',
        'cvicuOutflow',
        'cvicuSummary',
        'msicuSummary',
        'admissions',
    ],

    endpoints: (builder) => ({
        getMsicuOccupancy: builder.query<Occupancy, void>({
            // query: () => `/msicu`,
            // providesTags: ['msicuOccupancy'],
            // msicuOccupancyMock
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${criticalCarebaseURL}/msicu`,
                () => msicuOccupancyMock
            ),
        }),

        getCvicuOccupancy: builder.query<Occupancy, void>({
            // query: () => `/cvicu`,
            // providesTags: ['cvicuOccupancy'],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${criticalCarebaseURL}/cvicu`,
                () => cvicuOccupancyMock
            ),
        }),

        getResusPodsInflowData: builder.query<
            ResusPodsResponce,
            EmergencyCategory
        >({
            //@ts-ignore
            queryFn: createQueryFn(
                (emergencyCategory: EmergencyCategory) =>
                    `${criticalCarebaseURL}/resuspods/list?emergencyCategory=${emergencyCategory}`,
                (emergencyCategory: EmergencyCategory) =>
                    MocktResusPodsData[emergencyCategory]
            ),
        }),

        getOperatingRoomInflowData: builder.query<
            OperatingRoomCurrentInflowsResponse,
            void
        >({
            // query: () => `/operating-room-current-inflow/list`,
            // providesTags: ['operatingRoom'],
            //@ts-ignore
            queryFn: createQueryFn(
                () =>
                    `${criticalCarebaseURL}/operating-room-current-inflow/list`,
                () => orRoomsMock
            ),
        }),

        getPacuInflowData: builder.query<PacuResponce, void>({
            // query: () => `/pacu-inflow/list`,
            // providesTags: ['pacuInflow'],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${criticalCarebaseURL}/pacu-inflow/list`,
                () => pacuMock
            ),
        }),

        getTotalCurrentInflow: builder.query<
            CriticalCareTotalCurrentInflowsResponse,
            AdmittingType
        >({
            // query: (admittingType) => `/total-current-inflow/list?admittingType=${admittingType}`,
            // providesTags: ['totalCurrentInflow'],
            //@ts-ignore
            queryFn: createQueryFn(
                (admittingType) =>
                    `${criticalCarebaseURL}/total-current-inflow/list?admittingType=${admittingType}`,
                (admittingType) => MockTotalCurrentInflow[admittingType]
            ),
        }),

        getCurrentDemographics: builder.query<
            CurrentDemographicsResponse,
            AdmittingType
        >({
            // query: (admittingType) => `/current-demographics/list?demographicType=${admittingType}`,
            // providesTags: ['currentDemographics'],
            //@ts-ignore
            queryFn: createQueryFn(
                (admittingType) =>
                    `${criticalCarebaseURL}/current-demographics/list?demographicType=${admittingType}`,
                (admittingType) => MockCurrentDemographics[admittingType]
            ),
        }),

        getCurrentOutflow: builder.query<
            CriticalCareTotalCurrentOutflowsResponse,
            AdmittingType
        >({
            // query: (admittingType) => `/current-demographics/list?demographicType=${admittingType}`,
            // providesTags: ['currentDemographics'],
            //@ts-ignore
            queryFn: createQueryFn(
                (admittingType) =>
                    `${criticalCarebaseURL}/current-outflow/list?type=${admittingType}`,
                (admittingType) => MockOutflows[admittingType]
            ),
        }),
    }),
})

export const {
    useGetResusPodsInflowDataQuery,
    useGetTotalCurrentInflowQuery,
    useGetOperatingRoomInflowDataQuery,
    useGetPacuInflowDataQuery,
    useGetCvicuOccupancyQuery,
    useGetMsicuOccupancyQuery,
    useGetCurrentDemographicsQuery,
    useGetCurrentOutflowQuery,
} = criticalCareApi
