import { useKeycloak } from '@react-keycloak/web'
import { ReactNode } from 'react'
import Loader from 'components/Loader/Loader'
import Login from 'pages/Login/Login'

interface PrivateRouteProps {
    children: ReactNode
    allowedRoles?: string[] // Optional array of allowed roles
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    children,
    allowedRoles,
}) => {
    const { keycloak, initialized } = useKeycloak()

    if (!initialized) {
        return <Loader />
    }

    // Check if the user is authenticated
    const isAuthenticated = keycloak?.authenticated

    // Get user roles from the Keycloak token
    // const userRoles: string[] = keycloak?.tokenParsed?.realm_access?.roles || []
    const userRoles: string[] = ['admin']

    // Check if the user has the allowed roles
    const hasAccess = allowedRoles
        ? allowedRoles.some((role) => userRoles.includes(role))
        : true

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
        return <Login />
    }

    const styles: React.CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '40px',
    }
    // If authenticated but not authorized for the allowed roles, show unauthorized message
    if (!hasAccess) {
        return (
            <div style={styles}>
                You do not have permission to view this page.
            </div>
        )
    }

    // Render the protected content
    return <>{children}</>
}

export default PrivateRoute
