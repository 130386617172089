import { WardViewData } from 'redux/rtk/wardView/types'

export const WARD_PATIENTS_MOCK: WardViewData = {
    wards: [
        {
            id: 2024099974,
            patientId: '06a78bac-1dcf-4c02-9a37-4168b29db9fb',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 514,
            visitExpectedPlannedDischargedHours: 242,
            visitFollowingDoctorName: 'MAKKI LEEN',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0216',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '99256',
            patientFirstName: 'EMMA',
            patientName: 'ROY',
            patientGender: 'FEMALE',
            patientAge: 76,
            patientProvenance: 'Home',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [
                'has_clsc_mental_health_record',
                'is_highly_vulnerable',
                'has_mental_health_diagnosis',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101842,
            patientId: '37204b10-c5af-4dee-ada6-db82ef5dc5d9',
            visitDiagnosis: 'reconstruction acl (anterior crurate ligament)',
            visitLengthOfStaySinceAdmissionHours: 19,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0225',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '368364',
            patientFirstName: 'LUCAS',
            patientName: 'TREMBLAY',
            patientGender: 'MALE',
            patientAge: 78,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024099163,
            patientId: '7efea461-f55b-4d80-ad58-4b9f47d3d016',
            visitDiagnosis: 'non stemi',
            visitLengthOfStaySinceAdmissionHours: 706,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLOIS YVES',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0231',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1396750',
            patientFirstName: 'OLIVER',
            patientName: 'FORTIN',
            patientGender: 'MALE',
            patientAge: 75,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024099556,
            patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 634,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0255',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '988131',
            patientFirstName: 'LIAM',
            patientName: 'BERNIER',
            patientGender: 'MALE',
            patientAge: 67,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101929,
            patientId: '1fc5d078-6c14-4b75-9d3c-045249d968d1',
            visitDiagnosis: 'to be specified',
            visitLengthOfStaySinceAdmissionHours: 1,
            visitExpectedPlannedDischargedHours: 137,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0274',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1655142',
            patientFirstName: 'BENJAMIN',
            patientName: 'LAVOIE',
            patientGender: 'MALE',
            patientAge: 62,
            patientProvenance: null,
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101072,
            patientId: '1529aab6-c884-4b66-adbb-e42282e8c2be',
            visitDiagnosis: 'ami - myocardial infarct acute',
            visitLengthOfStaySinceAdmissionHours: 225,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0277',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809423',
            patientFirstName: 'ETHAN',
            patientName: 'DION-MARCK',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 4,
            completedImages: [
                {
                    patientId: '1529aab6-c884-4b66-adbb-e42282e8c2be',
                    uniqueId: 2024028042,
                    imageDescription:
                        'ANGIOLPASTY - PRIMARY - URGENT - 1 - 2 SEGMENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101856,
            patientId: '2da9280c-82f2-4384-a754-2b87445bc2fa',
            visitDiagnosis: 'av block, complete',
            visitLengthOfStaySinceAdmissionHours: 15,
            visitExpectedPlannedDischargedHours: 113,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0264',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1638034',
            patientFirstName: 'JACOB',
            patientName: 'DUBOIS',
            patientGender: 'MALE',
            patientAge: 87,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101539,
            patientId: 'c0a7810a-68aa-46cf-944a-9cb9c3b1d757',
            visitDiagnosis: 'stemi',
            visitLengthOfStaySinceAdmissionHours: 77,
            visitExpectedPlannedDischargedHours: 41,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0221',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '779825',
            patientFirstName: 'EMMA',
            patientName: 'ROY',
            patientGender: 'FEMALE',
            patientAge: 78,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more', 'has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 5,
            completedLabsCount: 6,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024094903,
            patientId: 'a9318747-c048-479d-98a6-08b87ad75263',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 1905,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'FADOUS MARIA',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0215',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1724094',
            patientFirstName: 'NATHAN',
            patientName: 'POULIN',
            patientGender: 'MALE',
            patientAge: 76,
            patientProvenance: null,
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_highly_vulnerable', 'is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024100234,
            patientId: '745622be-f4a2-4767-b084-8b0fba0d495d',
            visitDiagnosis: 'dm type 1',
            visitLengthOfStaySinceAdmissionHours: 448,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0236',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '161459',
            patientFirstName: 'AVA',
            patientName: 'GAGNON',
            patientGender: 'FEMALE',
            patientAge: 50,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 5,
            completedImages: [
                {
                    patientId: '745622be-f4a2-4767-b084-8b0fba0d495d',
                    uniqueId: 2024131791,
                    imageDescription: 'ABDOMEN 1 FILM',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101777,
            patientId: '7cabc455-dedb-464b-97b7-a00382b5bd2d',
            visitDiagnosis: 'pericarditis, acute',
            visitLengthOfStaySinceAdmissionHours: 30,
            visitExpectedPlannedDischargedHours: 137,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0250',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1810219',
            patientFirstName: 'SOPHIA',
            patientName: 'LAPOINTE',
            patientGender: 'FEMALE',
            patientAge: 58,
            patientProvenance: 'Hospital',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101508,
            patientId: '3ce0a452-6b89-47a0-83d9-e769705a6281',
            visitDiagnosis: 'ht - hypertension, benign',
            visitLengthOfStaySinceAdmissionHours: 88,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0257',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1199444',
            patientFirstName: 'RAPHAËL',
            patientName: 'CÔTÉ',
            patientGender: 'MALE',
            patientAge: 66,
            patientProvenance: 'Home',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024096975,
            patientId: 'df33223e-3f60-4d7c-bef5-44c57f239aab',
            visitDiagnosis: 'cabg x 2 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 1327,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'LANGLOIS YVES',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0244',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1802181',
            patientFirstName: 'WILLIAM',
            patientName: 'ST-PIERRE',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [
                'is_highly_vulnerable',
                'is_highly_vulnerable',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101768,
            patientId: 'c9422620-b701-4a35-95e3-f84daf5f2fe0',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 34,
            visitExpectedPlannedDischargedHours: 161,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0275',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '288773',
            patientFirstName: 'THOMAS',
            patientName: 'BÉLANGER',
            patientGender: 'MALE',
            patientAge: 84,
            patientProvenance: 'Clinic',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101766,
            patientId: '3a9709ae-7f8a-4018-85d8-a4a938274050',
            visitDiagnosis: 'ua - unstable angina',
            visitLengthOfStaySinceAdmissionHours: 34,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0212',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1207299',
            patientFirstName: 'ÉMILIE',
            patientName: 'GIRARD',
            patientGender: 'FEMALE',
            patientAge: 53,
            patientProvenance: 'Clinic',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024097688,
            patientId: '74807110-34af-4b65-bbbf-da5ee9d0c212',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 1099,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0254',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '439292',
            patientFirstName: 'CHLOÉ',
            patientName: 'PERRON',
            patientGender: 'FEMALE',
            patientAge: 68,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'is_highly_vulnerable',
                'has_ccc_mental_health_record',
                'has_vulnerability_signals',
                'has_home_support',
                'is_highly_vulnerable',
                'has_ccc_mental_health_record',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101745,
            patientId: '540c1983-77c5-4094-abe4-84ff2359a836',
            visitDiagnosis: 'cad - coronary artery disease',
            visitLengthOfStaySinceAdmissionHours: 39,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0210',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1810331',
            patientFirstName: 'OLIVER',
            patientName: 'GIRARD',
            patientGender: 'MALE',
            patientAge: 71,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101273,
            patientId: 'd52ba726-b5d4-4459-b3f3-f618781ca2dd',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 178,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0240',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1779280',
            patientFirstName: 'KEVIN',
            patientName: 'JACOB',
            patientGender: 'MALE',
            patientAge: 68,
            patientProvenance: 'Home',
            patientTerritory: 'Montérégie Centre',
            patientTerritoryAcronym: 'MRC',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101391,
            patientId: '606740b7-d6a1-4b7b-a13a-c52ea6e3593e',
            visitDiagnosis: 'necrotizing fasciitis',
            visitLengthOfStaySinceAdmissionHours: 147,
            visitExpectedPlannedDischargedHours: 209,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0276',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809839',
            patientFirstName: 'MIA',
            patientName: 'ST-PIERRE',
            patientGender: 'FEMALE',
            patientAge: 29,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: 'Abitibi Témiscamingue',
            patientTerritoryAcronym: 'AB',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 11,
            completedLabsCount: 6,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
        {
            id: 2024101669,
            patientId: '47d0ca61-6926-4411-b7c6-240bd240a0ad',
            visitDiagnosis: 'ua - unstable angina',
            visitLengthOfStaySinceAdmissionHours: 56,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            locationServiceName: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0224',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1359474',
            patientFirstName: 'WILLIAM',
            patientName: 'TREMBLAY',
            patientGender: 'MALE',
            patientAge: 91,
            patientProvenance: 'Hospital',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
        },
    ],
}

export const WARD_SSU_MOCK = {
    wards: [
        {
            id: 2024101922,
            patientId: '107d7260-e6e3-4082-9f96-a69992aab65d',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 12,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C633',
            locationBedName: 'A',
            actualTransferStatus: 'Signed Discharge',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
            ],
            patientRecord: '834603',
            patientFirstName: 'BOZENA',
            patientName: 'PAJAKOWSKA',
            patientGender: 'FEMALE',
            patientAge: 71,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 8,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024100670,
            patientId: '9fbf7ade-67a5-4ec4-a7c4-4273a608e6cd',
            visitDiagnosis: 'cellulitis',
            visitLengthOfStaySinceAdmissionHours: 355,
            visitExpectedPlannedDischargedHours: 105,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C637',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1808747',
            patientFirstName: 'JACQUES',
            patientName: 'HANDFIELD',
            patientGender: 'MALE',
            patientAge: 74,
            patientProvenance: 'Home',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024099738,
            patientId: '4543ed6f-07d5-4a63-92d3-c2981bf77fbf',
            visitDiagnosis: 'fx cervical vertebra, closed',
            visitLengthOfStaySinceAdmissionHours: 573,
            visitExpectedPlannedDischargedHours: 57,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C636',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1807259',
            patientFirstName: 'LILI',
            patientName: 'MARSOLAIS',
            patientGender: 'FEMALE',
            patientAge: 99,
            patientProvenance: 'Other',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101451,
            patientId: '859b8d7f-8074-498b-8bac-8b634a5e23f4',
            visitDiagnosis: 'sepsis - septicemia',
            visitLengthOfStaySinceAdmissionHours: 137,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C641',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809877',
            patientFirstName: 'DIANE',
            patientName: 'COLLETTE',
            patientGender: 'FEMALE',
            patientAge: 84,
            patientProvenance: 'Nursing home',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101760,
            patientId: '9e8b666b-ef23-4a6e-85f1-f1cfb035c89f',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 44,
            visitExpectedPlannedDischargedHours: 153,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C648',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1210776',
            patientFirstName: 'WILLIAM',
            patientName: 'GUERIN',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101432,
            patientId: 'cb875737-67e0-4aee-ba8e-d643156a1c70',
            visitDiagnosis: 'osteomyelitis',
            visitLengthOfStaySinceAdmissionHours: 141,
            visitExpectedPlannedDischargedHours: 345,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C634',
            locationBedName: 'B',
            actualTransferStatus: 'Signed Discharge',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
            ],
            patientRecord: '1430133',
            patientFirstName: 'PULENDRAN',
            patientName: 'PUTHIRASIGAMANI',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 6,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101276,
            patientId: 'a3e90403-d9d7-4515-902a-59e3103b72c0',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 185,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C638',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1099519',
            patientFirstName: 'SOTIRIOS',
            patientName: 'TSAPARAS',
            patientGender: 'MALE',
            patientAge: 89,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2023116278,
            patientId: 'c3f49393-c49c-45eb-a7c2-542f5040753f',
            visitDiagnosis: "crohn's disease",
            visitLengthOfStaySinceAdmissionHours: 4889,
            visitExpectedPlannedDischargedHours: 225,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C642',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '365736',
            patientFirstName: 'DEBRA HEMA',
            patientName: 'MAHABIR',
            patientGender: 'FEMALE',
            patientAge: 59,
            patientProvenance: 'Clinic',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024098581,
            patientId: '0a5f19a6-24e9-46ac-a06e-caa8d5a044d8',
            visitDiagnosis: 'generalized edema',
            visitLengthOfStaySinceAdmissionHours: 864,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C636',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1804575',
            patientFirstName: 'MARIE-PAULE',
            patientName: 'ST-CYR',
            patientGender: 'FEMALE',
            patientAge: 86,
            patientProvenance: 'Clinic',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'is_highly_vulnerable',
                'is_75_or_more',
                'has_home_support',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101769,
            patientId: '79b07f14-f743-4f04-bd46-6a7b3f5c14ff',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 42,
            visitExpectedPlannedDischargedHours: 129,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C632',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '92000',
            patientFirstName: 'SARITA',
            patientName: 'GOZLAN',
            patientGender: 'FEMALE',
            patientAge: 74,
            patientProvenance: 'Nursing home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024100198,
            patientId: 'b40fddf2-d1cd-4b58-84e0-3d1806961ffb',
            visitDiagnosis: 'copd exacerbation',
            visitLengthOfStaySinceAdmissionHours: 475,
            visitExpectedPlannedDischargedHours: 23,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C640',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '72605',
            patientFirstName: 'ISAAC',
            patientName: 'RAFFOUL-HARARI',
            patientGender: 'MALE',
            patientAge: 92,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'has_mental_health_diagnosis',
                'has_vulnerability_signals',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101483,
            patientId: 'db1c67b7-b97e-4099-8bfc-b2af5cfd426d',
            visitDiagnosis: 'myalgia',
            visitLengthOfStaySinceAdmissionHours: 115,
            visitExpectedPlannedDischargedHours: 273,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            locationServiceName: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C646',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1092025',
            patientFirstName: 'LEON J',
            patientName: 'MICHEL',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance: 'Home',
            patientTerritory: 'Montérégie Centre',
            patientTerritoryAcronym: 'MRC',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 5,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
    ],
}

export const WARD_MSISU_MOCK = {
    wards: [
        {
            id: 2024094985,
            patientId: '69de0bfb-0f5f-463a-b5dc-6ff9ada6ebab',
            visitDiagnosis: 'excision lesion nasopharyngeal',
            visitLengthOfStaySinceAdmissionHours: 1894,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0127',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1431981',
            patientFirstName: 'GEORGE',
            patientName: 'MACHHOUR',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 3,
            completedImages: [
                {
                    patientId: '69de0bfb-0f5f-463a-b5dc-6ff9ada6ebab',
                    uniqueId: 2024131634,
                    imageDescription: 'CT SCAN THORAX C- (inc. suprarenals)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024093304,
            patientId: '51f99c1b-23b9-4902-95bc-9732aca5b774',
            visitDiagnosis: 'neoplasm of breast',
            visitLengthOfStaySinceAdmissionHours: 2370,
            visitExpectedPlannedDischargedHours: 355,
            visitFollowingDoctorName: 'WONG EVAN',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0131',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1263746',
            patientFirstName: 'ANNY',
            patientName: 'KARIRE',
            patientGender: 'FEMALE',
            patientAge: 56,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 9,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101716,
            patientId: 'ab2d7cbd-02a5-4fe9-86c3-a5c639bc438e',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 60,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0130',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1808071',
            patientFirstName: 'MONIQUE',
            patientName: 'LAQUERRE',
            patientGender: 'FEMALE',
            patientAge: 71,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 17,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2023107440,
            patientId: 'fb8194d5-6f06-4942-8057-337c49fa55ff',
            visitDiagnosis: 'orchitis / epididymitis',
            visitLengthOfStaySinceAdmissionHours: 7374,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0114',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1602275',
            patientFirstName: 'SONG',
            patientName: 'SU',
            patientGender: 'MALE',
            patientAge: 61,
            patientProvenance: 'Home',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'is_living_alone',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 10,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101836,
            patientId: '9789d277-33f9-4f37-97e8-55976fbf633b',
            visitDiagnosis: 'post-op/wound infection',
            visitLengthOfStaySinceAdmissionHours: 29,
            visitExpectedPlannedDischargedHours: 201,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0129',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '599722',
            patientFirstName: 'EFTERPI',
            patientName: 'KAROUNOS',
            patientGender: 'FEMALE',
            patientAge: 57,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 10,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101907,
            patientId: '7fc6e4f4-1f8f-417a-abde-ce6d08ee899a',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 14,
            visitExpectedPlannedDischargedHours: 225,
            visitFollowingDoctorName: 'WONG EVAN',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0132',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '260863',
            patientFirstName: 'GEORGE',
            patientName: 'WISEMAN',
            patientGender: 'MALE',
            patientAge: 79,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 9,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024090800,
            patientId: '66a2b25f-2a18-4551-a974-0993320413e2',
            visitDiagnosis: 'respiratory failure',
            visitLengthOfStaySinceAdmissionHours: 3077,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0112',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '622222',
            patientFirstName: 'PAUL',
            patientName: 'HARARY',
            patientGender: 'MALE',
            patientAge: 75,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'is_75_or_more',
                'has_mental_health_diagnosis',
                'has_home_support',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 1,
            completedImages: [
                {
                    patientId: '66a2b25f-2a18-4551-a974-0993320413e2',
                    uniqueId: 2024131782,
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-29T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101046,
            patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
            visitDiagnosis: 'ami - myocardial infarct acute',
            visitLengthOfStaySinceAdmissionHours: 241,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0124',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '312616',
            patientFirstName: 'ARMAND',
            patientName: 'BEN-MOHA',
            patientGender: 'MALE',
            patientAge: 80,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 17,
            completedImages: [
                {
                    patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
                    uniqueId: 2024132217,
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-29T00:00:00',
                },
                {
                    patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
                    uniqueId: 2024028016,
                    imageDescription:
                        'LEFT HEART CATHETERIZATION AND CORONAROGRAPHY',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024100759,
            patientId: '1550e341-30fb-4d83-8b3b-f163c9450177',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 336,
            visitExpectedPlannedDischargedHours: 18,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0134',
            locationBedName: 'A',
            actualTransferStatus: 'Planned transfer',
            transferStatuses: [
                {
                    name: 'Planned transfer',
                    rank: 2,
                },
            ],
            patientRecord: '1808821',
            patientFirstName: 'MANON',
            patientName: 'DES LAURIERS',
            patientGender: 'FEMALE',
            patientAge: 55,
            patientProvenance: 'Home',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 12,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024100878,
            patientId: '338f4fbf-1219-46b2-b18e-9d1d242aa047',
            visitDiagnosis: 'leukemia',
            visitLengthOfStaySinceAdmissionHours: 311,
            visitExpectedPlannedDischargedHours: 858,
            visitFollowingDoctorName: 'WONG EVAN',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0125',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809048',
            patientFirstName: 'FARAN',
            patientName: 'ZAHID',
            patientGender: 'MALE',
            patientAge: 20,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 11,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024099965,
            patientId: 'a9462d14-f283-46e5-bdf0-4a1785cdab88',
            visitDiagnosis: 'pneumonia',
            visitLengthOfStaySinceAdmissionHours: 524,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'WONG EVAN',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0123',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1238564',
            patientFirstName: 'JERZY',
            patientName: 'CIESIELSKI',
            patientGender: 'MALE',
            patientAge: 69,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
        {
            id: 2024101663,
            patientId: '3e94db46-48e9-44c8-b773-8e7463a4ecd6',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 65,
            visitExpectedPlannedDischargedHours: 153,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            locationServiceName: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0120',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Planned transfer',
                    rank: 2,
                },
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1807443',
            patientFirstName: 'NADIA',
            patientName: 'BENZINE',
            patientGender: 'FEMALE',
            patientAge: 62,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
        },
    ],
}
