import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Link } from 'react-router-dom'
import NotFound from 'pages/NotFound/NotFound'

import styles from './ErrorPage.module.scss'

const ErrorPage: React.FC = () => {
    // const error = useRouteError() as { status?: number; message?: string }
    const error = useRouteError() as any
    console.log('🚀 ~ error:', error)

    const getErrorMessage = () => {
        if (error.status === 404) {
            return <NotFound />
        }
        return (
            error.statusText || 'Something went wrong. Please try again later.'
        )
    }

    return (
        <div className="error-page">
            <h1>{error.status || 500}</h1>
            <p>{getErrorMessage()}</p>
            <Link to="/">Go back to Home</Link>
        </div>
    )
}

export default ErrorPage
