import { RootState } from '..'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'development'

export const createQueryFn =
    <T, A>(getUrl: (arg: A) => string, getMockData: (arg: A) => T) =>
    async (
        arg: A,
        { getState }: Pick<BaseQueryApi, 'getState'>
    ): Promise<{ data?: T; error?: FetchBaseQueryError }> => {
        try {
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)

            const headers = new Headers()
            if (token) {
                headers.set('bearer', `${token}`)
                // headers.set('Authorization', `Bearer ${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }

            const url = getUrl(arg)
            const response = await fetch(url, { headers })

            if (isDevelopment) {
                // Handling HTTP error statuses
                return { data: getMockData(arg) }
            }

            const data = await response.json()
            return { data }
        } catch (error) {
            // Handling network or unexpected errors
            return {
                error: {
                    status: 'FETCH_ERROR',
                    data: undefined,
                    error: `An error occurred in ${arg}`,
                } as FetchBaseQueryError,
            }
        }
    }
