import React from 'react'
import { BrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import MainPage from './pages/MainPage'
import './i18n'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { keycloak } from './utils/Keycloak'
import storeRtk, { persistor } from './redux/rtk'
import { setKeycloakToken } from './redux/rtk/auth/authSlice'
import { MantineProvider } from '@mantine/core'
import router from 'routes/Routes'

import 'App.module.scss'

function App() {
    return (
        <ReactKeycloakProvider
            initOptions={{
                pkceMethod: 'S256',
                responseMode: 'fragment',
                flow: 'standard',
                checkLoginIframe: true,
            }}
            authClient={keycloak}
            // this for testing
            onTokens={({ token }) => {
                // @ts-ignore
                localStorage.setItem('keycloakToken', token)
                storeRtk.dispatch(setKeycloakToken(token))
                // storeRtk.dispatch(setKeycloakToken(token))
            }}
        >
            <Provider store={storeRtk}>
                <MantineProvider defaultColorScheme="dark">
                    <PersistGate loading={null} persistor={persistor}>
                        <RouterProvider router={router} />
                    </PersistGate>
                </MantineProvider>
            </Provider>
        </ReactKeycloakProvider>
    )
}

export default App
