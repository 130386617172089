import React, { useEffect, useMemo, useState } from 'react'
import styles from './NotFound.module.scss'
import Header from '../../components/Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '@mantine/core'
import {
    useGetDepartmentsTabsQuery,
    useLazyGetDepartmentsTabsQuery,
} from '../../redux/rtk/departmentTabs/departmentTabsSlice'
import { selectToken } from '../../redux/rtk/auth/selectors'
import { selectActiveUserRole } from '../../redux/rtk/user/selectors'
import { useGetShowTermsOfServiceQuery } from '../../redux/rtk/terms/termsConditionsSlice'
import { selectIsAcceptedTerms } from '../../redux/rtk/termsAndOther/selectors'
import { useConditionalQuery } from '../../hooks'
import { setAcceptTerms } from '../../redux/rtk/termsAndOther/termsAndOtherSlice'
import { Link } from 'react-router-dom'

const NotFound = () => {
    const dispatch = useDispatch()
    const userToken = useSelector(selectToken)
    const activeUserRole = useSelector(selectActiveUserRole)
    const isAcceptedTerms = useSelector(selectIsAcceptedTerms)
    const { data: termsAndConditionsData } = useGetShowTermsOfServiceQuery(
        undefined,
        {
            skip: !userToken,
        }
    )
    // const fetchContitions = [!!userToken, !!activeUserRole, isAcceptedTerms]
    const fetchContitions = useMemo(
        () => [
            !!userToken,
            !!activeUserRole,
            isAcceptedTerms,
            // !!termsAndConditionsData &&
            //     !termsAndConditionsData?.showTermsAndConditions,
        ],
        [userToken, activeUserRole, termsAndConditionsData, isAcceptedTerms]
    )
    const { data, error, isFetching, isSuccess } = useConditionalQuery(
        useGetDepartmentsTabsQuery,
        undefined,
        fetchContitions
    )
    return (
        <div className={styles.wrapper}>
            {/* <Header /> */}

            <section className={styles.section}>
                {/* {isSuccess && data && data?.departments?.length > 0 ? ( */}
                <div className={styles.section__text}>Page not found</div>

                <Link to="/">Go back to Home</Link>
            </section>
        </div>
    )
}

export default NotFound
