import React from 'react'
import { Button, Menu } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { selectTheme } from '../../../../redux/rtk/user/selectors'
import { NavigationItem } from '../../MenuOptions'

import styles from './NavigationDropdown.module.scss'

interface NavigationDropdownProps {
    items: NavigationItem[]
    reset: (menuItem: string) => void
}

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({
    items,
    reset,
}) => {
    const { t } = useTranslation()
    const theme = useSelector(selectTheme)

    const isLight: boolean = theme === 'light'

    return items.length > 0 ? (
        <Menu
            position="bottom-start"
            transitionProps={{ transition: 'rotate-right', duration: 150 }}
            trigger="click"
            classNames={{
                dropdown: `${styles[theme + '-theme']} ${
                    styles.navigation_tab_body
                }`,
                item: `${
                    isLight
                        ? styles.navigation_tab_body_light_bg
                        : styles.navigation_tab_body_dark_bg
                }`,
            }}
        >
            <Menu.Target>
                <Button
                    variant="subtle"
                    classNames={{
                        root: `${styles.navigation_tab__button__root}`,
                        label: `${styles.navigation_tab__button__label}`,
                    }}
                    rightSection={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                        >
                            <path
                                d="M6.4611 2.85503C5.73741 2.13134 4.5 2.64389 4.5 3.66735L4.5 14.5989C4.5 15.6224 5.73741 16.1349 6.4611 15.4112L11.9269 9.94544C12.3755 9.49681 12.3755 8.76944 11.9269 8.32081L6.4611 2.85503Z"
                                fill="url(#paint0_linear_7324_11812)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_7324_11812"
                                    x1="8.41086"
                                    y1="15.75"
                                    x2="12.605"
                                    y2="4.01527"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#86B4D9" />
                                    <stop offset="1" stopColor="#7EC6DF" />
                                </linearGradient>
                            </defs>
                        </svg>
                    }
                >
                    More Wards
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                {items?.map((menuItem, index) => {
                    return (
                        <div key={index}>
                            <Menu.Item
                                className={styles.menu_item}
                                // key={menuItem.link.replace('/', '')}
                                key={index}
                            >
                                <NavLink
                                    to={menuItem.link}
                                    className={({
                                        isActive,
                                    }: {
                                        isActive: boolean
                                    }) => (isActive ? styles.active : '')}
                                    onClick={() => reset(menuItem.title)}
                                >
                                    <span>{t(`${menuItem.title}`)}</span>
                                </NavLink>
                            </Menu.Item>
                            {index !== items.length - 1 && <Menu.Divider />}
                        </div>
                    )
                })}
            </Menu.Dropdown>
        </Menu>
    ) : null
}

export default NavigationDropdown
