import React, { ReactElement, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'components/Header/Header'
import { useAppDispatch } from 'redux/rtk'
import { useSelector } from 'react-redux'
import { useKeycloak } from '@react-keycloak/web'
import { getAuthData } from 'redux/rtk/auth/authSlice'

import styles from './Layout.module.scss'
import { selectTheme } from 'redux/rtk/user/selectors'
import FeedbackButton from 'components/common/FeedbackButton/FeedbackButton'

const Layout = (): ReactElement => {
    const dispatch = useAppDispatch()
    const { initialized, keycloak } = useKeycloak()
    const theme = useSelector(selectTheme)

    useEffect(() => {
        if (!initialized) return
        if (!keycloak.authenticated) return

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(-1)
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => {}
        }
    }, [initialized, keycloak.authenticated, keycloak])

    useEffect(() => {
        if (keycloak.authenticated && keycloak.token) {
            let name =
                (keycloak?.tokenParsed?.name?.length > 0 &&
                    keycloak?.tokenParsed?.name) ||
                (keycloak?.tokenParsed?.given_name &&
                    keycloak?.tokenParsed?.family_name &&
                    `${keycloak?.tokenParsed?.given_name} ${keycloak?.tokenParsed?.family_name}`) ||
                keycloak?.tokenParsed?.preferred_username
            dispatch(
                getAuthData({
                    userName: name,
                    userRole: keycloak.realmAccess?.roles || [],
                    authorized: keycloak.authenticated || false,
                    userToken: keycloak.token,
                    // lastSinceTime: new Date(),
                })
            )
        }
    }, [keycloak, dispatch, keycloak.authenticated])

    return (
        <div className={`${styles.layout} ${styles[theme + '-theme']}`}>
            <Header />
            <main className={styles.layout__main}>
                <Outlet /> {/* This will render the nested routes */}
            </main>
            <FeedbackButton
                text="Feedback"
                feedbackUrl="https://forms.office.com/r/qaP05geHQE"
            />
        </div>
    )
}

export default Layout
