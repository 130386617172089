import { useParams } from 'react-router-dom'
import { MedicalUnitType } from 'redux/rtk/wardView/types'
import { useGetWardViewDataQuery } from 'redux/rtk/wardView/wardViewApi'

// Custom hook to handle fetching ward data
export const useWardViewData = () => {
    const { department } = useParams<{ department: string }>()

    // Default to Cardiology if department is undefined or doesn't exist in MedicalUnitType
    const medicalUnitType =
        department && (MedicalUnitType as any)[department] !== undefined
            ? (MedicalUnitType as any)[department]
            : MedicalUnitType['Cardiology']

    const queryResponse = useGetWardViewDataQuery(
        { medicalUnitType, page: 1, size: 100 },
        {
            pollingInterval: 300000, // Poll every 5 minutes
        }
    )

    return queryResponse
}
