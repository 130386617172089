import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { MedicalUnitType, WardViewData, WardViewQueryParams } from './types'
import { backendApiUrl } from 'utils/API'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import {
    WARD_MSISU_MOCK,
    WARD_PATIENTS_MOCK,
    WARD_SSU_MOCK,
} from 'utils/__Mocks__/wardViewMock'
import qs from 'qs'

const mock_map = {
    MSICU: WARD_MSISU_MOCK,
    SSU: WARD_SSU_MOCK,
    Cardiology: WARD_PATIENTS_MOCK,
}

export const wardViewApi = createApi({
    reducerPath: 'wardViewApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendApiUrl}/api/v1/Wards`,
        prepareHeaders,
    }),

    tagTypes: ['wardViewData'],

    endpoints: (builder) => ({
        getWardViewData: builder.query<WardViewData, WardViewQueryParams>({
            // query: ({ medicalUnitType, page, size }) => {
            //     const queryParams = {
            //         MedicalUnitType: MedicalUnitType[medicalUnitType],
            //         Page: page,
            //         Size: size,
            //     }

            //     // Filter out undefined values automatically
            //     const queryString = qs.stringify(queryParams, {
            //         skipNulls: true,
            //     })

            //     return `?${queryString}`
            // },
            //@ts-ignore
            queryFn: createQueryFn(
                ({
                    medicalUnitType,
                    page,
                    size,
                }: {
                    medicalUnitType: MedicalUnitType
                    page?: number
                    size?: number
                }) => {
                    const queryParams = {
                        MedicalUnitType: MedicalUnitType[medicalUnitType],
                        Page: page,
                        Size: size,
                    }

                    // Build the query string, skipping null/undefined values
                    const queryString = qs.stringify(queryParams, {
                        skipNulls: true,
                    })
                    console.log('🚀 ~ queryString:', queryString)
                    return `${backendApiUrl}/api/v1/Wards?${queryString}`
                },
                () => WARD_PATIENTS_MOCK
            ),
        }),
    }),
})

export const { useGetWardViewDataQuery } = wardViewApi
